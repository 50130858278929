<template>
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
    <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo">

      <div class="text-right leading-tight hidden sm:block">
        <p class="font-semibold">{{ activeUserInfo.full_name }}</p>
        <small>Available</small>
      </div>

      <!--      <div class="con-img ml-3">-->
      <!--        <img v-if="activeUserInfo" key="onlineImg" src="@/assets/images/portrait/small/avatar-s-11.jpg" alt="user-img"-->
      <!--             width="40" height="40" class="rounded-full shadow-md cursor-pointer block"/>-->
      <!--      </div>-->

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Profile</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MailIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Inbox</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Tasks</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Chat</span>
          </li>

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="HeartIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Wish List</span>
          </li>

          <vs-divider class="m-1"/>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </div>
  </vs-dropdown>

</template>

<script>
import axios from "axios";
import * as storage from "@/utils/storage";
import {clearTokens, clearUserInfo} from "@/services/auth";

export default {
  data() {
    return {}
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    logout() {
      clearTokens();
      clearUserInfo();
      this.$router.push('/login');
    },
    getLoggedInUser() {
      axios.get('v1/admin/me').then((res) => {
        storage.set('userInfo', JSON.stringify(res.data.user));
      }).then(() => {
          this.$store.commit('UPDATE_USER_INFO', JSON.parse(storage.get('userInfo')));
        }
      )
    }
  },
  mounted() {
    this.getLoggedInUser()
  }
}
</script>
